import { useIntl } from 'src/domains/i18n';
import { Layout } from 'src/features/shared/components/layout';
import { Section } from '@features/shared/components/layout';
import {
  Body,
  FontWeight,
  H3,
} from 'src/features/shared/components/typography';
import { ContentBlocks } from 'src/features/shared/contentful/types/IContentPage';
import { useContentIndex } from 'src/features/shared/hooks/use-content-index';
import { ButtonVariant } from '../../types/enums/ButtonVariant';
import { Button } from '../button';
import styles from './ContentIndex.module.scss';

export interface IContentIndexProps {
  contentBlocks: ContentBlocks;
  title?: string;
}

export const ContentIndex = ({ contentBlocks, title }: IContentIndexProps) => {
  const contentIndex = useContentIndex(contentBlocks);
  const { formatMessage } = useIntl();
  if (contentIndex?.length === 0) {
    return null;
  }

  const onScrollToHeader = (e: React.MouseEvent, label: string) => {
    e.preventDefault();
    const elements = document.querySelectorAll('h2');
    for (const element of Array.from(elements)) {
      if (element.textContent === label) {
        const elementPosition = element.offsetTop - 150;
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth',
        });
        break;
      }
    }
  };

  return (
    <Section className={styles.section}>
      <Layout.Container>
        <Layout.Row className={styles.row}>
          <Layout.Col
            columns="xs:12 sm:10 md:8"
            offset="sm:1 md:2"
            className={styles.col}
          >
            <div className={styles.content}>
              <H3 className={styles.title}>
                {title ||
                  formatMessage('content-page.content-index.default-title')}
              </H3>
              {contentIndex?.map(({ label }, index) => (
                <div key={index} className={styles.listItem}>
                  <Body fontWeight={FontWeight.Bold}>{`${index + 1}.`}</Body>
                  <Button
                    variant={ButtonVariant.Link}
                    className={styles.link}
                    onClick={(e) => onScrollToHeader(e, label)}
                  >
                    {label}
                  </Button>
                </div>
              ))}
            </div>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
};
