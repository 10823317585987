'use client';
import ConfettiEffect from '@features/pg-funnel/components/confetti';
import ContentBlocksSectionView from '@views/sections/content-blocks';
import { CONTENTFUL_TAGS } from 'src/features/pg-funnel/utils/constants';
import { ContentIndex } from 'src/features/shared/components/content-index';
import { PageHeader } from 'src/features/shared/components/page-header';
import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';

interface IContentPageViewProps {
  page: IContentPage;
}

export const ContentPageView = ({ page }: IContentPageViewProps) => {
  // Only pages with tag SEO should display the content index component
  const showContentIndex = page.contentfulTags.includes(CONTENTFUL_TAGS.seo);

  return (
    <>
      {page.showConfetti && <ConfettiEffect />}

      <PageHeader pageHeader={page.pageHeader} />
      {showContentIndex && (
        <ContentIndex
          title={page.contentTableTitle}
          contentBlocks={page.contentBlocks}
        />
      )}

      {page.contentBlocks && (
        <ContentBlocksSectionView
          page={page}
          contentBlocks={page.contentBlocks}
        />
      )}
    </>
  );
};
