import classNames from 'classnames';

import { Fragment } from 'react';
import styles from './PageTitle.module.scss';
import HightlightSvg from '../../../../../assets/icons/title-highlight-bg.svg';

export interface IPageTitleProps {
  isLight?: boolean;
  title: string;
  className?: string;
}

export const PageTitle = ({ isLight, title, className }: IPageTitleProps) => {
  const pattern = /([^*]*)\*(.*?)\*([^*]*)/g;
  const matches = [];
  let match;
  while ((match = pattern.exec(title)) !== null) {
    matches.push({
      textBefore: match[1],
      highlightedText: match[2],
      textAfter: match[3] || '',
    });
  }

  return (
    <h1
      className={classNames(
        styles.pageTitle,
        { [styles.light]: isLight },
        className,
      )}
    >
      {matches.length
        ? matches.map((match, index) => (
            <Fragment key={index}>
              {match.textBefore}
              <span className={styles.highlightedText}>
                <HightlightSvg />
                {match.highlightedText}
              </span>
              {match.textAfter}
            </Fragment>
          ))
        : title}
    </h1>
  );
};
