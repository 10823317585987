import { useEffect, useState, useRef } from 'react';
import { isVariantActive } from './isVariantActive';

interface VariationDecidedEvent {
  data: {
    experience_id: string;
    variation_id: string;
    experience_name: string;
    variation_name: string;
  };
  event: 'experience.variation_decided';
}

export function useConvertVariant(
  experimentId: string,
  variantId: string,
): boolean | null {
  const mounted = useRef(false);
  const [isActive, setIsActive] = useState<null | boolean>(null);

  useEffect(() => {
    mounted.current = true;

    const onVariationDecided = (event: VariationDecidedEvent) => {
      if (!mounted.current) {
        return;
      }

      const variantIsActive =
        event.data.experience_id === experimentId &&
        event.data.variation_id === variantId;
      setIsActive(variantIsActive);
    };

    const _conv_q = window._conv_q || [];
    _conv_q.push({
      what: 'addListener',
      params: {
        event: 'experience.variation_decided',
        handler: onVariationDecided,
      },
    });

    const active = isVariantActive(experimentId, variantId);
    setIsActive(active);

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isActive;
}
