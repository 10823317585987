import { IVideoPageHeader } from 'src/features/shared/contentful/types/IVideoPageHeader';
import { mockMappedLink } from 'src/features/shared/contentful/types/__mocks__/mappedLink';
import { mockVideoAsset } from 'src/features/shared/utils/__mocks__/video-asset';
import { useConvertVariant } from 'src/services/convert/useConvertVariant';

const data: IVideoPageHeader = {
  type: 'videoPageHeader',
  title: 'Voor die *gymlach*',
  subtitle: 'Sporten vanaf €26,99 per 4 weken',
  backgroundVideo: mockVideoAsset({
    url: 'https://videos.ctfassets.net/bian31qh2p30/3VuEGtUpfMM7cR5F09WTuw/bf3889439ccec2eca231988039907ab4/Sportcity_Banner_V4_COMPRESSED.webmhd.webm',
  }),
  mobileBackgroundVideo: mockVideoAsset({
    url: 'https://videos.ctfassets.net/bian31qh2p30/3t3c9WrWubt1OYytOwDPjD/50516245ed6ba816faf500702a347c30/Sportcity_Banner_V4_COMPRESSED_mobile.mp4',
  }),
  primaryButton: mockMappedLink({
    label: 'Vind jouw club',
    href: '/checkout/default',
  }),
  secondaryButton: mockMappedLink({
    label: 'Bekijk lidmaatschap',
    href: '/lidmaatschap',
  }),
  hasClubSearch: true,
  theme: 'Dark',
};

export function useVideoHeaderExperiment() {
  const isActive = useConvertVariant('1004121710', '1004288788');
  console.log(isActive);
  return {
    isActive,
    data,
  };
}
