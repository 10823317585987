import isServer from 'src/utils/is-server';

export function isVariantActive(
  experimentId: string,
  variantId: string,
): boolean {
  if (isServer) {
    return false;
  }

  const isActive =
    window?.convert?.currentData?.experiences?.[experimentId]?.variation.id ===
    variantId;

  // Retrieve the convertPreview value from the URL search params
  const urlSearchParams = new URLSearchParams(window.location.search);
  const convertPreviewParam = urlSearchParams.get('_conv_eforce');
  if (convertPreviewParam) {
    const [previewExperimentId, previewVariantId] =
      convertPreviewParam.split('.');

    if (
      previewExperimentId === experimentId &&
      previewVariantId === variantId
    ) {
      return true;
    }
  }

  return isActive;
}
