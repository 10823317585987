import React, { ComponentProps, KeyboardEvent } from 'react';
import styles from './ClubSearchInput.module.scss';
import { Input } from 'src/features/shared/components/input';
import { SearchButton } from 'src/features/shared/components/search-button';
import { UseMyLocationButton } from 'features/shared/components/use-my-location-button';

interface IClubSearchInputProps extends ComponentProps<typeof Input> {
  onSubmit: () => void;
  useMyLocationButtonProps: Omit<
    ComponentProps<typeof UseMyLocationButton>,
    'isLight'
  >;
}

export function ClubSearchInput({
  onSubmit,
  isAlternative,
  useMyLocationButtonProps,
  ...inputProps
}: IClubSearchInputProps) {
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <>
      <div className={styles.body}>
        <div className={styles.wrapper}>
          <div className={styles['input-container']}>
            <Input
              isSearch
              onKeyDown={onKeyDown}
              id="search-box"
              isAlternative={isAlternative}
              {...inputProps}
            />
          </div>
          <SearchButton onClick={onSubmit} type="button" />
        </div>
      </div>
      <div className={styles.footer}>
        <UseMyLocationButton
          {...useMyLocationButtonProps}
          isLight={isAlternative}
        />
      </div>
    </>
  );
}
