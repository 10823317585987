import { useIntl } from 'src/domains/i18n';
import { useVideoHeaderExperiment } from 'src/experiments/videoheader/useVideoHeaderExperiment';
import { ColorPageHeader } from 'src/features/shared/components/page-header/color-page-header/ColorPageHeader';
import { ImagePageHeader } from 'src/features/shared/components/page-header/image-page-header/ImagePageHeader';
import { VideoPageHeader } from 'src/features/shared/components/video-page-header';
import {
  IPageHeader,
  PageHeaderBackgroundStyle,
} from 'src/features/shared/contentful/types/IPageHeader';
import { IVideoPageHeader } from 'src/features/shared/contentful/types/IVideoPageHeader';
import ClubSearchBarSectionView from 'src/views/sections/club-search-bar';

export interface IPageHeaderProps {
  pageHeader: IPageHeader | IVideoPageHeader | null;
}

export const PageHeader = ({ pageHeader }: IPageHeaderProps) => {
  const { formatMessage } = useIntl();

  //** Remove when VideoHeaderExperiment has finished */
  const videoHeaderExperiment = useVideoHeaderExperiment();

  if (videoHeaderExperiment.isActive) {
    return <VideoPageHeader data={videoHeaderExperiment.data} />;
  }

  const { ColorSoftPeach, ColorSoftSand, ColorYellow } =
    PageHeaderBackgroundStyle;
  if (!pageHeader) {
    return null;
  }

  if (pageHeader.type === 'videoPageHeader') {
    return <VideoPageHeader data={pageHeader} />;
  }

  if (
    [ColorSoftPeach, ColorSoftSand, ColorYellow].includes(
      pageHeader.backgroundStyle,
    )
  ) {
    return <ColorPageHeader pageHeader={pageHeader} />;
  }

  return (
    <ImagePageHeader pageHeader={pageHeader}>
      {pageHeader?.hasClubSearch && (
        <ClubSearchBarSectionView
          redirect
          inputPlaceholder={formatMessage(
            'content-page.club-search.placeholder',
          )}
          hasFontColorLight={
            pageHeader?.backgroundStyle !== 'Collage' &&
            pageHeader.hasFontColorLight
          }
        />
      )}
    </ImagePageHeader>
  );
};
