import styles from './index.module.scss';
import { useIntl } from 'domains/i18n';
import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { ClubSearchInput } from 'src/features/shared/components/club-search-input';

export default function ClubSearchBarSectionView({
  hasFontColorLight,
  setFilteredClubs,
  search,
  useMyLocation,
  inputPlaceholder,
}) {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const isLightTheme = useMemo(() => {
    return hasFontColorLight !== false;
  }, [hasFontColorLight]);

  const handleInputChange = useCallback(
    (event) => {
      setSearchTerm(event.target.value);
    },
    [setSearchTerm],
  );

  const onSearch = () => {
    return router.push(`/clubs?searchQuery=${searchTerm}`);
  };

  const onClickUseMyLocation = () => {
    return router.push(
      `/clubs?searchNearby=true&searchQuery=${formatMessage(
        'club-detail.clubs-nearby.query',
      )}`,
    );
  };

  const onClear = useCallback(() => {
    if (setFilteredClubs) {
      setFilteredClubs(null);
    }
    setSearchTerm('');
  }, [setFilteredClubs, setSearchTerm]);

  return (
    <div className={styles.container}>
      <ClubSearchInput
        value={searchTerm}
        onChange={handleInputChange}
        onClear={onClear}
        onSubmit={onSearch}
        isAlternative={isLightTheme}
        placeholder={
          inputPlaceholder ||
          formatMessage('checkout.select-club.search.placeholder')
        }
        useMyLocationButtonProps={{
          onClick: onClickUseMyLocation,
        }}
      />
    </div>
  );
}

ClubSearchBarSectionView.propTypes = {
  hasFontColorLight: PropTypes.bool.isRequired,
  setFilteredClubs: PropTypes.func,
  search: PropTypes.string,
  redirect: PropTypes.bool,
  useMyLocation: PropTypes.string,
  inputPlaceholder: PropTypes.string,
};
