import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import styles from './VideoPageHeader.module.scss';
import { Button } from 'src/features/shared/components/button';
import { H4 } from 'src/features/shared/components/typography';
import { ClubSearchInput } from 'src/features/shared/components/video-page-header/club-search-input';
import { PageTitle } from 'src/features/shared/components/video-page-header/page-title/PageTitle';
import { StickerButton } from 'src/features/shared/components/video-page-header/sticker-button/StickerButton';
import { IVideoPageHeader } from 'src/features/shared/contentful/types/IVideoPageHeader';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';

interface IVideoPageHeaderProps {
  data: IVideoPageHeader;
}

export function VideoPageHeader({ data }: IVideoPageHeaderProps) {
  const isLightTheme = data.theme === 'Light';
  const videoRef = useRef<HTMLVideoElement>(null);
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  useEffect(() => {
    if ('connection' in navigator && navigator?.connection) {
      const connection = navigator.connection as { effectiveType: string };
      if (!['slow-2g', '2g', '3g'].includes(connection.effectiveType)) {
        videoRef.current?.play();
      }
    } else {
      videoRef.current?.play();
    }
  }, []);

  useEffect(() => {
    if (!videoRef.current) return;
    if (isMobile) {
      videoRef.current.src = data.mobileBackgroundVideo.url;
      videoRef.current.playsInline = true;
    } else {
      videoRef.current.src = data.backgroundVideo.url;
    }

    videoRef.current.load();
  }, [isMobile, videoRef]);

  const onButtonClick = (ctaType: string, ctaName: string, url: string) => {
    pushGA4Event(GA4_EVENTS.callToAction, {
      call_to_action_type: ctaType,
      interaction_type: 'click',
      call_to_action_name: ctaName,
      link_url: url,
    });
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.lightTheme]: isLightTheme,
      })}
    >
      <div className={styles.videoBackground}>
        <video
          ref={videoRef}
          muted
          loop
          onCanPlayThrough={() => {
            videoRef.current?.play();
          }}
          data-testid="bg-video"
        />
        <div className={styles.titleWrapper}>
          <PageTitle title={data.title} />
          <H4 className={styles.subtitle}>{data.subtitle}</H4>

          <StickerButton
            visibleOn="mobile"
            image={data.stickerButtonImage}
            link={data.stickerButtonLink}
            onClick={() =>
              onButtonClick(
                'image link',
                'sticker button',
                data.stickerButtonLink ? data.stickerButtonLink.href : '',
              )
            }
          />
        </div>
      </div>
      <div className={styles.inputWrapper}>
        {data.hasClubSearch && <ClubSearchInput isLightTheme={isLightTheme} />}
        <StickerButton
          visibleOn="desktop"
          image={data.stickerButtonImage}
          link={data.stickerButtonLink}
          onClick={() =>
            onButtonClick(
              'image link',
              'sticker button',
              data.stickerButtonLink ? data.stickerButtonLink.href : '',
            )
          }
        />
      </div>
      {data.primaryButton || data.secondaryButton ? (
        <div className={styles.buttonsWrapper}>
          {data.primaryButton && (
            <Button
              className={classNames(styles.button, styles.primaryButton)}
              variant={ButtonVariant.PrimaryInverted}
              onClick={() =>
                onButtonClick(
                  'button',
                  'video header primary button',
                  data.primaryButton ? data.primaryButton.href : '',
                )
              }
              href={data.primaryButton.href}
            >
              {data.primaryButton.label}
            </Button>
          )}
          {data.secondaryButton && (
            <Button
              variant={
                isLightTheme
                  ? ButtonVariant.Inverted
                  : ButtonVariant.SecondaryInverted
              }
              onClick={() =>
                onButtonClick(
                  'button',
                  'video header secondary button',
                  data.secondaryButton ? data.secondaryButton.href : '',
                )
              }
              href={data.secondaryButton.href}
              className={styles.button}
            >
              {data.secondaryButton.label}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
}
