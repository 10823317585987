import classNames from 'classnames';
import styles from './SearchButton.module.scss';
import Icons from 'features/shared/components/icons';
import { ButtonHTMLAttributes } from 'react';
import { useIntl } from 'src/domains/i18n';

interface ISearchButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  className?: string;
}

export function SearchButton({
  onClick,
  className: givenClassName,
  ...props
}: ISearchButtonProps) {
  const { formatMessage } = useIntl();

  return (
    <button
      className={classNames(styles.button, givenClassName)}
      onClick={onClick}
      id="club-search"
      data-testid="club-search-button"
      aria-label={formatMessage('club-search-input.submit.arial-label')}
      {...props}
    >
      <Icons.Search />
    </button>
  );
}
