import { ContentBlocks } from 'src/features/shared/contentful/types/IContentPage';
import { ILinkList } from 'src/features/shared/contentful/types/ILinkList';
import { ICardItem } from 'src/features/shared/contentful/types/ICardItem';
import { ISection } from 'src/features/shared/contentful/types/ISection';
import { IVideoSection } from 'src/features/shared/contentful/types/IVideoSection';

interface IIndex {
  label: string;
  anchorLink: string;
}

export function crateAnchorLink(inputString: string) {
  return inputString
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/-/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();
}

export function useContentIndex(contentBlocks: ContentBlocks) {
  if (!contentBlocks) return null;
  const index: IIndex[] = [];

  for (const i in contentBlocks) {
    const block = contentBlocks[i];

    if (block.type === 'section') {
      const headers = (block as ISection).description.content.filter(
        (item) => item.nodeType === 'heading-2',
      );
      headers.map((item) => {
        const header = item.content && item.content[0].value;
        if (header) {
          index.push({
            label: header,
            anchorLink: crateAnchorLink(header),
          });
        }
      });
    }

    if (
      ['newsSection', 'videoSection', 'imageSection'].includes(block.type || '')
    ) {
      const header = (block as ICardItem | ILinkList | IVideoSection).title;
      index.push({
        label: header,
        anchorLink: crateAnchorLink(header),
      });
    }
  }

  return index;
}
